<template>
  <div>
    <v-container fluid>
      <v-row align="center" justify="space-between" class="font-weight-black">
        <v-col cols="12" sm="11" class="pa-0">
          <v-breadcrumbs :items="breadcrumbs" divider="/">
            <template v-slot:item="props">
              <v-breadcrumbs-item
                :disabled="props.item.disabled"
                :class="{
                  'LiquidacaoNivel_breadcrumbs-item': true,
                  'LiquidacaoNivel_breadcrumbs-item_disabled': props.item.disabled,
                }"
                @click.native="navegarBreadcrumbs(props.item)">
                {{ props.item.text }}
              </v-breadcrumbs-item>
            </template>
          </v-breadcrumbs>
        </v-col>
        <v-spacer></v-spacer>
        <v-col cols="12" sm="1" class="text-right pa-0 pr-3" v-if="exibirFiltro">
          <v-badge
            bordered
            color="orange"
            icon="mdi-lock"
            overlap
            v-show="!abrirFiltro"
            v-if="habilitarIconBadge">
            <v-icon v-show="!abrirFiltro" @click="abrirFiltro = !abrirFiltro">filter_list</v-icon>
          </v-badge>
          <v-icon v-else v-show="!abrirFiltro" @click="abrirFiltro = !abrirFiltro">filter_list</v-icon>
        </v-col>
      </v-row>
    </v-container>

    <liquidacao-filtro
      v-model="abrirFiltro"
      v-if="exibirFiltro"
      entidade-workspace="liquidacao"
      @LiquidacaoFiltro__AplicaFiltros="aplicarFiltros"
      @FiltroRapido__HabilitaIconBadge="habilitaIconBadge">
    </liquidacao-filtro>

    <liquidacao-cards-totalizadores
      ref="cards_totalizadores"
      v-if="exibirFiltro"
      :filtros="filtros"
      visao="visao_acao"
      percentual="monetario">
    </liquidacao-cards-totalizadores>

    <transition name="slide-fade"
                mode="out-in">
      <router-view></router-view>
    </transition>

  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Confirm from '@/shared-components/dialog/Confirm';
import LiquidacaoFiltro from '@/spa/liquidacao-acao/LiquidacaoFiltro';
import LiquidacaoCardsTotalizadores from '@/spa/liquidacao-acao/LiquidacaoCardsTotalizadores';
import LiquidacaoControleBreadcrumbs from '@/spa/liquidacao-acao/LiquidacaoControleBreadcrumbs';
import { generateComputed } from '@/common/functions/roles-computed-generator';

export default {
  data() {
    return {
      abrirFiltro: false,
      filtros: null,
      habilitarIconBadge: false,
    };
  },
  mixins: [LiquidacaoControleBreadcrumbs],
  components: {
    Confirm,
    LiquidacaoFiltro,
    LiquidacaoCardsTotalizadores,
  },
  computed: {
    ...mapGetters('roles', [
      'getAllRoles',
    ]),
    ...generateComputed('REC_ACAO', [
      'canAccessPage',
    ]),
    canAccessPagePag() {
      if (!this.getAllRoles) {
        return false;
      }
      return !!this.getAllRoles
        .filter((el) => el.startsWith('PGTO') || el === 'ACESSO_CLIENTE').length;
    },
  },
  methods: {
    ...mapActions('filtros', [
      'setFiltroLiquidacaoAcao',
      'setFiltroLiquidacaoAcaoNivel1',
    ]),
    accessForbidden() {
      if (!this.canAccessPage && !this.canAccessPagePag) {
        this.$router.push({ name: 'inicio' });
        this.$toast(this.$t('message.acesso_negado'));
      }
    },
    aplicarFiltros(filtrosAplicados) {
      if (!filtrosAplicados || !this.filtroPeriodoValido(filtrosAplicados)) {
        return;
      }
      const filtros = {
        ...filtrosAplicados,
      };
      this.filtros = filtros;
      this.setFiltroLiquidacaoAcao(filtros);
      this.setFiltroLiquidacaoAcaoNivel1(null);
    },
    habilitaIconBadge(valor) {
      this.habilitarIconBadge = valor;
    },
    filtroPeriodoValido(filtrosAplicados) {
      if (filtrosAplicados.data_inicio || filtrosAplicados.data_fim) {
        let dtInicio = '';
        let dtFinal = '';
        dtInicio = this.moment(filtrosAplicados.data_inicio, 'YYYY-MM-DD');
        dtFinal = this.moment(filtrosAplicados.data_fim, 'YYYY-MM-DD');

        if (dtFinal.isBefore(dtInicio)) {
          this.$toast(this.$t('message.data_final_antes_inicial'));
          return false;
        }
        if ((filtrosAplicados.data_inicio && !filtrosAplicados.data_fim)
          || (!filtrosAplicados.data_inicio && filtrosAplicados.data_fim)) {
          this.$toast(this.$t('message.data_final_e_inicial'));
          return false;
        }
        return true;
      }
      return true;
    },
  },
  beforeMount() {
    this.setFiltroLiquidacaoAcao(null);
    this.setFiltroLiquidacaoAcaoNivel1(null);
    this.setFiltroLiquidacaoAcaoNivel2(null);
  },
  mounted() {
    window.setTimeout(() => this.accessForbidden(), 1E3);
  },
};
</script>
<style lang="scss">
  .Barra_Breadcrumbs {
    .v-breadcrumbs {
      padding: 0.5em 0 1em 0;
    }
    ul:not(.md-list)>li+li {
      margin-top: 0 !important;
    }
  }
  .Barra_Exibicao {
    min-height: 41px !important;
    text-align: center;
    padding: 8px 0;
  }
  .Barra_Exibicao div.v-input__slot, .Barra_Exibicao div.v-messages, .Barra_Exibicao .v-icon {
    margin: 0 !important;
    padding: 0;
    min-height: 0;
  }
  .Barra_Pesquisa {
    width: 33%;
  }
  .LiquidacaoNivel_breadcrumbs-item_disabled {
    color: grey;
    pointer-events: none;
  }
  .LiquidacaoNivel_breadcrumbs-item {
    color: grey;
    cursor: pointer;
  }
  .LiquidacaoNivel_breadcrumbs-item:hover {
    color: #3f3f3f;
  }
</style>
